import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const YorisenTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "受注番号", value: "acceptNo" },
  { text: "作業者", value: "userId" },
  { text: "機械名", value: "machineCode" },
  { text: "仕様書No", value: "specificationNo" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "実撚り上がり条長(m)", value: "yoriagari" },
  { text: "数量明細(m)", value: "suryoMeisai" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class YorisenTable {
  workDate: string;
  updateTs: string | null;
  acceptNo: string | null;
  userId: string | null;
  postCode: string | null;
  machineCode: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  yoriagari: number | null;
  suryoMeisai: string | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    acceptNo: string | null;
    userId: string | null;
    postCode: string | null;
    machineCode: string | null;
    specificationNo: string | null;
    itemName: string | null;
    size: string | null;
    yoriagari: number | null;
    suryoMeisai: string | null;
    abnormality: string | null;
    biko: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.acceptNo = props.acceptNo;
    this.updateTs = null;
    this.userId = props.userId;
    this.postCode = props.postCode;
    this.machineCode = props.machineCode;
    this.specificationNo = props.specificationNo;
    this.itemName = props.itemName;
    this.size = props.size;
    this.yoriagari = props.yoriagari;
    this.suryoMeisai = props.suryoMeisai;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const YorisenText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  postCode: { text: "部署コード", value: "postCode" },
  machineCode: { text: "機械名", value: "machineCode" },
  userId: { text: "作業者", value: "userId" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFlag: { text: "受注番号品 完了", value: "successFlag" },
  assemblyNo: { text: "集合番号", value: "assemblyNo" },
  specificationNo: { text: "仕様書No", value: "specificationNo" },
  workContents: {
    text: "作業内容\n(下撚/上撚/対撚/テープ巻/巻替/廃棄)",
    value: "workContents"
  },
  conductorType: { text: "導体種類", value: "conductorType" },
  conductorStructure: { text: "導体構成", value: "conductorStructure" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  zenkeijaku: { text: "前計尺条長(m)", value: "zenkeijaku" },
  yoriagari: { text: "実撚り上がり条長(m)", value: "yoriagari" },
  suryoMeisai: { text: "数量明細(m)", value: "suryoMeisai" },
  drumNo: { text: "巻取りドラムNo", value: "drumNo" },
  drumType: { text: "巻取りドラム種類", value: "drumType" },
  diceFirst: { text: "第1層ダイス(mm)", value: "diceFirst" },
  yoriFirst: { text: "第1層撚り方向", value: "yoriFirst" },
  pitchFirst: { text: "第1層測定ピッチ(mm)", value: "pitchFirst" },
  diceSecond: { text: "第2層ダイス(mm)", value: "diceSecond" },
  yoriSecond: { text: "第2層撚り方向", value: "yoriSecond" },
  pitchSecond: { text: "第2層測定ピッチ(mm)", value: "pitchSecond" },
  diceThird: { text: "第3層ダイス(mm)", value: "diceThird" },
  yoriThird: { text: "第3層撚り方向", value: "yoriThird" },
  pitchThird: { text: "第3層測定ピッチ(mm)", value: "pitchThird" },
  diceFourth: { text: "最外層ダイス(mm)", value: "diceFourth" },
  yoriFourth: { text: "最外層撚り方向", value: "yoriFourth" },
  pitchFourth: { text: "最外層測定ピッチ(mm)", value: "pitchFourth" },
  yoriOuter: { text: "撚り上がり外径(m)", value: "yoriOuter" },
  arrayConfirm: { text: "配列確認", value: "arrayConfirm" },
  tubeType: { text: "中心介在種類/チューブ種類", value: "tubeType" },
  leadWire1: { text: "リード線1", value: "leadWire1" },
  leadWire2: { text: "リード線2", value: "leadWire2" },
  kaizaiFirst: { text: "第1層線間介在種類", value: "kaizaiFirst" },
  kaizaiSecond: { text: "第2層線間介在種類", value: "kaizaiSecond" },
  kaizaiThird: { text: "第3層線間介在種類", value: "kaizaiThird" },
  kaizaiFourth: { text: "最外層線間介在種類", value: "kaizaiFourth" },
  gear: { text: "ギヤ", value: "gear" },
  insertTape: { text: "挿入テープ種類", value: "insertTape" },
  eMark: { text: "<PS>Eマーク入り", value: "eMark" },
  tapeType: { text: "テープ種類", value: "tapeType" },
  tapeRange: { text: "テープ幅(mm)", value: "tapeRange" },
  tapeRap: { text: "テープラップ", value: "tapeRap" },
  processType: { text: "編組加工種類", value: "processType" },
  processPlace: { text: "編組加工先", value: "processPlace" },
  appearance: { text: "導体・撚線外観", value: "appearance" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Yorisen extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  userId: string | null;
  inspectionFlag: string | null;
  acceptNo: string | null;
  successFlag: string | null;
  assemblyNo: string | null;
  specificationNo: string | null;
  workContents: string | null;
  conductorType: string | null;
  conductorStructure: string | null;
  itemName: string | null;
  size: string | null;
  zenkeijaku: number | null;
  yoriagari: number | null;
  suryoMeisai: string | null;
  drumNo: string | null;
  drumType: string | null;
  diceFirst: number | null;
  yoriFirst: string | null;
  pitchFirst: number | null;
  diceSecond: number | null;
  yoriSecond: string | null;
  pitchSecond: number | null;
  diceThird: number | null;
  yoriThird: string | null;
  pitchThird: number | null;
  diceFourth: number | null;
  yoriFourth: string | null;
  pitchFourth: number | null;
  yoriOuter: number | null;
  arrayConfirm: string | null;
  tubeType: string | null;
  leadWire1: string | null;
  leadWire2: string | null;
  kaizaiFirst: string | null;
  kaizaiSecond: string | null;
  kaizaiThird: string | null;
  kaizaiFourth: string | null;
  gear: string | null;
  insertTape: string | null;
  eMark: string | null;
  tapeType: string | null;
  tapeRange: number | null;
  tapeRap: string | null;
  processType: string | null;
  processPlace: string | null;
  appearance: string | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Yorisen);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Yorisen | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Yorisen) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.userId = _props.userId;
      this.inspectionFlag = _props.inspectionFlag;
      this.acceptNo = _props.acceptNo;
      this.successFlag = _props.successFlag;
      this.assemblyNo = _props.assemblyNo;
      this.specificationNo = _props.specificationNo;
      this.workContents = _props.workContents;
      this.conductorType = _props.conductorType;
      this.conductorStructure = _props.conductorStructure;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.zenkeijaku = _props.zenkeijaku;
      this.yoriagari = _props.yoriagari;
      this.suryoMeisai = _props.suryoMeisai;
      this.drumNo = _props.drumNo;
      this.drumType = _props.drumType;
      this.diceFirst = _props.diceFirst;
      this.yoriFirst = _props.yoriFirst;
      this.pitchFirst = _props.pitchFirst;
      this.diceSecond = _props.diceSecond;
      this.yoriSecond = _props.yoriSecond;
      this.pitchSecond = _props.pitchSecond;
      this.diceThird = _props.diceThird;
      this.yoriThird = _props.yoriThird;
      this.pitchThird = _props.pitchThird;
      this.diceFourth = _props.diceFourth;
      this.yoriFourth = _props.yoriFourth;
      this.pitchFourth = _props.pitchFourth;
      this.yoriOuter = _props.yoriOuter;
      this.arrayConfirm = _props.arrayConfirm;
      this.tubeType = _props.tubeType;
      this.leadWire1 = _props.leadWire1;
      this.leadWire2 = _props.leadWire2;
      this.kaizaiFirst = _props.kaizaiFirst;
      this.kaizaiSecond = _props.kaizaiSecond;
      this.kaizaiThird = _props.kaizaiThird;
      this.kaizaiFourth = _props.kaizaiFourth;
      this.gear = _props.gear;
      this.insertTape = _props.insertTape;
      this.eMark = _props.eMark;
      this.tapeType = _props.tapeType;
      this.tapeRange = _props.tapeRange;
      this.tapeRap = _props.tapeRap;
      this.processType = _props.processType;
      this.processPlace = _props.processPlace;
      this.appearance = _props.appearance;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "YORISN";
    this.machineCode = null;
    this.userId = null;
    this.inspectionFlag = "0";
    this.acceptNo = null;
    this.successFlag = "0";
    this.assemblyNo = null;
    this.specificationNo = null;
    this.workContents = null;
    this.conductorType = null;
    this.conductorStructure = null;
    this.itemName = null;
    this.size = null;
    this.zenkeijaku = null;
    this.yoriagari = null;
    this.suryoMeisai = null;
    this.drumNo = null;
    this.drumType = null;
    this.diceFirst = null;
    this.yoriFirst = null;
    this.pitchFirst = null;
    this.diceSecond = null;
    this.yoriSecond = null;
    this.pitchSecond = null;
    this.diceThird = null;
    this.yoriThird = null;
    this.pitchThird = null;
    this.diceFourth = null;
    this.yoriFourth = null;
    this.pitchFourth = null;
    this.yoriOuter = null;
    this.arrayConfirm = "0";
    this.tubeType = null;
    this.leadWire1 = null;
    this.leadWire2 = null;
    this.kaizaiFirst = null;
    this.kaizaiSecond = null;
    this.kaizaiThird = null;
    this.kaizaiFourth = null;
    this.gear = null;
    this.insertTape = null;
    this.eMark = "0";
    this.tapeType = null;
    this.tapeRange = null;
    this.tapeRap = null;
    this.processType = null;
    this.processPlace = null;
    this.appearance = null;
    this.abnormality = null;
    this.biko = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
